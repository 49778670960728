import React, { useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Chart as ChartJS } from 'chart.js';
import color from 'color';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import {
  DashboardType,
  IAggregatedStats,
  ICampaignOfferTrend,
  ICampaignOrOffer,
  IGrossRow,
  IIncrementalRow,
  ITrendWithProducts
} from 'types/global';

import { useTheme } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import styled from '@mui/system/styled';
import { GridFilterModel, GridLogicOperator, useGridApiRef } from '@mui/x-data-grid-pro';
import { NonEmptyDateRange } from '@mui/x-date-pickers-pro/internal/models';

import { getCampaignsAndOffers, getChartData, getKPI } from '../apis/dashboard';
import { SessionContext } from '../auth';
import ChartContainer from '../components/DataGrid/ChartContainer';
import DashboardTable from '../components/DataGrid/DashboardTable';
import DateRangePicker from '../components/DateRangePicker';
import MiscFilters from '../components/MiscFilters';
import PDFDownload from '../components/PDFDownload';
import useAsyncEffect from '../hooks/useAsyncEffect';
import { HeaderContext } from '../layouts/main/header/context';
import { IProfileDomain } from '../types/users';

import DashboardContext, { DashboardContextValue, SummaryTrendColor, TrendColor } from './DashboardContext';

const OptionOffer = styled('span')({
  fontWeight: 800,
});

const OptionCampaign = styled('span')(({ theme }) => ({
  fontWeight: 800,
  color: theme.palette.secondary.dark,
}));

const Option = styled('li')(({ theme }) => ({
  '& > span:not(:last-child)::after': {
    display: 'inline',
    content: '"/"',
    margin: '0 4px',
    color: theme.palette.primary.main,
    fontWeight: 400,
  },
}));

interface DashboardProps<R extends IIncrementalRow | IGrossRow> {
  dashboardType: R extends IIncrementalRow ? DashboardType.Incremental : DashboardType.Gross;
}

const COLORS = ['#C41515', '#E06307', '#EBB114', '#1FA219', '#0F8044', '#199CE5', '#4051B6', '#050074', '#050074', '#EB6DD3'];

function Dashboard<R extends IIncrementalRow | IGrossRow>({ dashboardType }: DashboardProps<R>) {
  const theme = useTheme();
  const { profile } = useContext(SessionContext);
  const { domainId, organizationId } = useContext(HeaderContext);
  const [rows, setRows] = useState<R[] | null>(null);
  const [searchOptions, setSearchOptions] = useState<ICampaignOrOffer[]>([]);
  useAsyncEffect(async () => {
    const { data } = await getCampaignsAndOffers(organizationId);
    setSearchOptions(data);
  }, [organizationId]);
  const [searchValue, setSearchValue] = useState<ICampaignOrOffer[]>([]);
  const [filteredOffers, setFilteredOffers] = useState<ICampaignOrOffer[]>([]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    logicOperator: GridLogicOperator.And,
  });
  const today = dayjs().startOf('day');
  const [dateRange, setDateRange] = useState<NonEmptyDateRange<Dayjs>>([
    dayjs(today).subtract(30, 'days'),
    today,
  ]);
  // const [visitorsChecked, setVisitorsChecked] = useState<Record<string, boolean>>({
  //   firstPurchase: false,
  //   repeatPurchaser: false,
  //   rewardsMember: false,
  // });
  const [selectedOffers, setSelectedOffers] = useState<R[] | null>(null);
  const [chartData, setChartData] = useState<ICampaignOfferTrend[] | null>(null);
  useLayoutEffect(() => {
    setRows(null);
  }, [filteredOffers]);
  const chartMinDate = (filteredOffers.length > 0 && (rows?.length || 0) > 0 && chartData)
    ? _.minBy(chartData.flatMap((x) => x.points), (x) => dayjs(x.date).valueOf())!.date
    : dateRange[0]!.format('YYYY-MM-DD');
  const chartMaxDate = (filteredOffers.length > 0 && (rows?.length || 0) > 0 && chartData)
    ? _.maxBy(chartData.flatMap((x) => x.points), (x) => dayjs(x.date).valueOf())!.date
    : dateRange[1]!.format('YYYY-MM-DD');
  const [dataDashboardType, setDataDashboardType] = useState<DashboardType>(dashboardType);
  const prevDomainId = useRef(domainId);
  useAsyncEffect(async () => {
    const type = dashboardType === DashboardType.Incremental ? 'incremental' : 'gross' as any;
    if (domainId === prevDomainId.current) {
      if (filteredOffers.length > 0) {
        const [
          { data },
          { data: newChartData },
        ] = await Promise.all([
          getKPI({ filter: filteredOffers, type }, profile!.organizations),
          getChartData({ filter: filteredOffers, type }, profile!.organizations),
        ]);
        setRows(data as any);
        setChartData(newChartData);
        setDataDashboardType(dashboardType);
        setSelectedOffers(null);
      }
    } else {
      setSearchValue([]);
      setFilteredOffers([]);
    }
    prevDomainId.current = domainId;
  }, [filteredOffers, dashboardType, domainId, profile!.organizations]);
  useAsyncEffect(async () => {
    const type = dashboardType === DashboardType.Incremental ? 'incremental' : 'gross' as any;
    if (filteredOffers.length === 0 && dateRange[0] && dateRange[1]) {
      const [
        { data },
        { data: newChartData },
      ] = await Promise.all([
        getKPI({
          domain: domainId,
          startDate: dateRange[0].format('YYYY-MM-DD'),
          endDate: dateRange[1].format('YYYY-MM-DD'),
          type,
        }, profile!.organizations),
        getChartData({ domain: domainId, startDate: chartMinDate, endDate: chartMaxDate, type }, profile!.organizations),
      ]);
      setRows(data as any);
      setChartData(newChartData);
      setDataDashboardType(dashboardType);
      setSelectedOffers(null);
    }
  }, [dateRange, domainId, filteredOffers, chartMinDate, chartMaxDate, dashboardType, profile!.organizations]);
  const [aggregatedStats, setAggregatedStats] = useState<IAggregatedStats | null>(null);
  const chartRef = useRef<ChartJS>(null);
  const [hoveredTrend, setHoveredTrend] = useState<string | null>(null);
  const [trendColors, setTrendColors] = useState<TrendColor[]>([]);
  useEffect(() => {
    if (rows) {
      const campaignColors = rows.filter((x) => !('offer' in x)).map((x, i) => ({
        campaign: x.campaign,
        color: COLORS[i % COLORS.length],
      }));
      const offerColors = rows.filter((x) => 'offer' in x && !('device' in x)).map((x, i) => {
        const campaignColor = campaignColors.find((y) => y.campaign === x.campaign);
        const offerIndex = rows.filter((y) => (
          y.campaign === x.campaign && 'offer' in y && !('device' in y)
        )).findIndex((y) => y.offer === x.offer);
        const darkenRatios = [0.2, 0.4, 0.6, 0.8, 1, 1.2];
        const lightenRatios = [0.5, 1, 1.5, 2, 2.5, 3];
        const offerColor = (() => {
          if (!campaignColor) {
            return COLORS[i % COLORS.length];
          }
          const colorObject = color(campaignColor.color);
          const ratios = colorObject.luminosity() > 0.1 ? darkenRatios : lightenRatios;
          return colorObject.lighten(ratios[offerIndex % ratios.length]).hex();
        })();
        return {
          campaign: x.campaign,
          offer: x.offer,
          color: offerColor,
        };
      });
      const summaryColor: SummaryTrendColor = { isSummary: true, color: '#EB6DD3' };
      setTrendColors([...campaignColors, ...offerColors, summaryColor]);
    }
  }, [rows]);
  const apiRef = useGridApiRef();
  const [deviceColumnHidden, setDeviceColumnHidden] = useState(false);
  const contextValue = useMemo((): DashboardContextValue => ({
    dateRange: filteredOffers.length > 0 ? [null, null] : dateRange,
    hoveredTrend,
    setHoveredTrend,
    trendColors,
    setTrendColors,
    apiRef,
  }), [dateRange, filteredOffers, hoveredTrend, trendColors]);
  if (!profile) {
    return null;
  }
  const chartDataWithProducts: ITrendWithProducts[] | null = useMemo(
    () => (rows && chartData) ? chartData.map((trend) => {
      const { products } = rows.find((row) => (
        ('offer' in trend ? row.offer === trend.offer : true)
        && row.campaign === trend.campaign
      )) as R;
      const points = trend.points.map((point) => ({
        ...point,
        ...(products.includes('cs') ? { cartCreationChange: undefined } : {}),
      }));
      return { ...trend, products, points };
    }) : null,
    [rows, chartData],
  );
  const performanceChartData = useMemo(() => {
    if (rows) {
      return rows.filter((x) => !x.offer).map((row) => ({
        x: row.campaign,
        y: dashboardType === DashboardType.Incremental
          ? (row as IIncrementalRow).incrementalRevenue
          : (row as IGrossRow).grossRevenue,
      }));
    }
    return null;
  }, [rows, dashboardType]);
  const displayDateRange: NonEmptyDateRange<Dayjs> = filteredOffers.length > 0 ? [dayjs(chartMinDate), dayjs(chartMaxDate)] : dateRange;
  const singleDomain = profile.organizations.length === 1 && profile.organizations[0].domains.length === 1;
  return (
    <DashboardContext.Provider value={contextValue}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          background: theme.palette.secondary.lighter,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            margin: { xs: '8px 0', lg: '16px 0 28px' },
          }}
        >
          <Autocomplete
            multiple
            value={searchValue}
            onChange={(_event, value, reason) => {
              setSearchValue(value);
              if (reason === 'clear') {
                setFilteredOffers([]);
              }
            }}
            options={searchOptions}
            groupBy={(option) => 'offer' in option ? 'Offers' : 'Campaigns'}
            getOptionLabel={(option) => {
              const domain = profile.organizations.flatMap((x) => x.domains)
                .find((x) => x.id === option.domain) as IProfileDomain;
              const offer = 'offer' in option ? `${option.offer} / ` : '';
              const keywords = option.keywords ? `${option.keywords.join(', ')} / ` : '';
              return `${offer}${keywords}${option.campaign}${singleDomain ? '' : ` / ${domain.url}`}`;
            }}
            renderOption={(props, option) => {
              const domain = profile.organizations.flatMap((x) => x.domains)
                .find((x) => x.id === option.domain) as IProfileDomain;
              return (
                <Option {...props}>
                  {'offer' in option && (
                    <OptionOffer>
                      {option.offer}
                    </OptionOffer>
                  )}
                  {option.keywords && (
                    <OptionOffer>
                      {option.keywords.join(', ')}
                    </OptionOffer>
                  )}
                  <OptionCampaign>
                    {option.campaign}
                  </OptionCampaign>
                  {!singleDomain && (
                    <span>
                      {domain.url}
                    </span>
                  )}
                </Option>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={
                  searchValue.length > 0 ? undefined : 'Search and select campaigns or offers'
                }
              />
            )}
            sx={{ flex: 1, display: { xs: 'none', lg: 'block' } }}
          />
          <Button
            variant="contained"
            size="large"
            sx={{ marginLeft: '8px', display: { xs: 'none', lg: 'block' } }}
            onClick={() => setFilteredOffers(searchValue)}
          >
            View Selected
          </Button>
          <MiscFilters
            filterModel={filterModel}
            setFilterModel={setFilterModel}
            deviceColumnHidden={deviceColumnHidden}
            // visitorsChecked={visitorsChecked}
            // setVisitorsChecked={setVisitorsChecked}
          />
          <DateRangePicker
            value={displayDateRange}
            onChange={setDateRange}
            TextFieldProps={{
              sx: { marginLeft: 1.75, width: 244 }, disabled: filteredOffers.length > 0,
              'aria-label': 'Select date range',
            }}
          />
          <PDFDownload
            dateRange={displayDateRange}
            aggregatedStats={aggregatedStats}
            chartRef={chartRef}
            dashboardType={dataDashboardType}
            apiRef={apiRef}
            selectedOffers={selectedOffers}
          />
        </Box>
        {chartDataWithProducts && performanceChartData && apiRef?.current?.state && (
          <ChartContainer
            selectedOffers={selectedOffers}
            minDate={chartMinDate}
            maxDate={chartMaxDate}
            filteredOffers={filteredOffers}
            chartData={chartDataWithProducts}
            setAggregatedStats={setAggregatedStats}
            chartRef={chartRef}
            dashboardType={dataDashboardType}
            performanceChartData={performanceChartData}
          />
        )}
        <DashboardTable
          rows={rows}
          selectedOffers={selectedOffers}
          setSelectedOffers={setSelectedOffers}
          filteredOffers={filteredOffers}
          filterModel={filterModel}
          setFilterModel={setFilterModel}
          dashboardType={dataDashboardType as any}
          dateRange={displayDateRange}
          apiRef={apiRef}
          setDeviceColumnHidden={setDeviceColumnHidden}
        />
      </Box>
    </DashboardContext.Provider>
  );
}

function WrappedDashboard() {
  const { dashboardType } = useContext(HeaderContext);
  return <Dashboard dashboardType={dashboardType} />;
}

export default WrappedDashboard;
