import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import dayjs from 'dayjs';

import { Theme } from '@mui/material/styles';

export default function DatePickers() {
  return {
    MuiPickersArrowSwitcher: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          '& .MuiTypography-root': {
            fontVariant: 'all-small-caps',
            fontSize: '14px',
            color: theme.palette.primary.main,
            margin: '0 14px 2px',
          },
        }),
        button: ({ theme }: { theme: Theme }) => ({
          color: theme.palette.primary.main,
        }),
      },
    },
    MuiDateRangeCalendar: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => {
          const mondayFirst = dayjs().weekday(0).isoWeekday() === 1;
          return {
            '& .MuiListItem-root': {
              padding: '4px',
            },
            [theme.breakpoints.up('lg')]: {
              '& .MuiDateRangeCalendar-monthContainer:first-of-type .MuiIconButton-edgeStart': {
                display: 'none',
              },
              '& .MuiDateRangeCalendar-monthContainer:last-of-type .MuiIconButton-edgeEnd': {
                display: 'none',
              },
              '& .MuiPickersArrowSwitcher-root': {
                borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
                padding: '1px 8px',
              },
            },
            '& .MuiDateRangeCalendar-monthContainer:not(:last-of-type)': {
              borderRight: '1px solid rgba(145, 158, 171, 0.24)',
            },
            '& .MuiDayCalendar-weekDayLabel': {
              color: '#00000061',
              [`&${mondayFirst ? ':nth-child(6)' : ':first-child'}, &:nth-child(7)`]: {
                color: '#FF6D00',
              },
            },
            '& .MuiDateRangePickerDay-root': {
              '& .MuiButtonBase-root:not(.Mui-selected):not(.Mui-disabled)': {
                color: '#000000DE',
              },
              [`&${mondayFirst ? ':nth-child(6)' : ':first-child'}, &:nth-child(7)`]: {
                '& .MuiButtonBase-root:not(.Mui-selected):not(.Mui-disabled)': {
                  color: '#FF6D00',
                },
              },
              '& .Mui-selected': {
                color: theme.palette.grey[0],
              },
            },
          };
        },
      },
    },
    MuiDateCalendar: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => {
          const mondayFirst = dayjs().weekday(0).isoWeekday() === 1;
          return {
            '& .MuiDateCalendar-viewTransitionContainer': {
              borderTop: `2px solid ${theme.palette.grey[300]}`,
            },
            '& .MuiPickersCalendarHeader-label': {
              color: theme.palette.primary.main,
              fontWeight: 'bold',
              textTransform: 'uppercase',
            },
            '& .MuiDayCalendar-weekDayLabel': {
              color: '#00000061',
              [`&${mondayFirst ? ':nth-child(6)' : ':first-child'}, &:nth-child(7)`]: {
                color: '#FF6D00',
              },
            },
            '& .MuiDayCalendar-weekContainer': {
              '& .MuiButtonBase-root:not(.Mui-selected):not(.Mui-disabled)': {
                color: '#000000DE',
              },
              '& .MuiButtonBase-root': {
                [`&${mondayFirst ? ':nth-child(6)' : ':first-child'}, &:nth-child(7)`]: {
                  '&:not(.Mui-selected):not(.Mui-disabled)': {
                    color: '#FF6D00',
                  },
                },
              },
              '& .Mui-selected': {
                color: theme.palette.grey[0],
              },
            },
          };
        },
      },
    },
    MuiDatePicker: {
      styleOverrides: {
        root: {
          '& .MuiInputAdornment-root': {
            color: 'inherit',
          },
        },
      },
      defaultProps: {
        slots: {
          openPickerIcon: CalendarIcon,
        },
        localeText: {
          fieldMonthPlaceholder: (params: any) => (params.contentType === 'letter' ? 'MMM' : 'MM'),
        },
      },
    },
    MuiPickersPopper: {
      styleOverrides: {
        paper: {
          borderRadius: 0,
        },
      },
    },
    MuiMobileDateRangePicker: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          '& .MuiMultiInputDateRangeField-separator': {
            color: theme.palette.primary.main,
          },
        }),
      },
    },
    MuiMultiInputDateRangeField: {
      styleOverrides: {
        separator: ({ theme }: { theme: Theme }) => ({
          color: theme.palette.primary.main,
        }),
      },
    },
    // MuiInputAdornment: {
    //   styleOverrides: {
    //     positionEnd: {
    //       '& button': {
    //         background: `url(${CalendarIcon}) no-repeat center center`,
    //         '& svg': {
    //           opacity: 0,
    //         },
    //       },
    //     },
    //   },
    // },
  };
}
